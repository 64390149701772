'use client';
import {useEffect} from 'react';

export default function GlobalError({error, reset}: {error: Error & {digest?: string}; reset: () => void}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
    // @ts-ignore
    window?.newrelic?.noticeError(error);
  }, [error]);

  return (
    <html>
      <body>
        <h2>Something went wrong!</h2>
        <button onClick={() => reset()}>Try again</button>
      </body>
    </html>
  );
}
